::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */


.main{
    height: 100vh;
    width: 100vw;
    display: grid;
}

.setupWrapper{
    height: 90vh;
    width: 100vw;
    display: grid;
    place-items: center;
    overflow: hidden;
    background-color: #202225;
}

.setup{
    height: 95%;
    width: 98%;
    background-color: #36393F;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-radius: 25px;
}

.dropdownContainer{
    height: 50%;
    width: 30%;
    display: flex;
    flex-direction: column;
    grid-template-rows: 2fr 2fr 1fr;
}

.detailsDiv{
    height: 30%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 2fr;
    place-items: center;
}

.dropdowns{
    border-radius: 25px;
    height: 50%;
    height: 5vh;
    width: 20vw;
    background-color: #202225;
    color: white;
    text-align: center;
    outline: none;
    border-color: transparent;
    font-size: 90%;

}

select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
  }
  
  /* For IE10 */
  select::-ms-expand {
    display: none;
}

.dropdownLabel{
    font-family: 'Uni Sans Bold';
    color: white;
    font-size: 130%;
    padding:0;
}

.selectSchedule{
    height: 15vh;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 2fr;
    align-items: center;
}

.start{
    height: 20%;
    width: 100%;
    display: grid;
    place-items: center;
}

.purpleButton{
    height: 50%;
    width: 10vw;
    border-radius: 25px;
    outline: none;
    border-color: transparent;
    background-color: #9667FF;
    color: white;
    font-family: 'Gilroy';
    font-weight: bold;
    font-size: 120%;
    transition: 0.2s all ease;
    cursor: pointer;
}

.purpleButton:hover {
    background-color: #9f3da8;
}

.schedulesWrapper{
    height: 95%;
    width: 100%;
    border-left: 1px solid rgb(117, 117, 117);
    display: grid;
    place-items: center;
    overflow: auto;
}

.schedulesContainer{
    height: 100%;
    width: 100%;
    margin-left: 5rem;
    overflow: auto;
}

.scheduleCard{
    border-radius: 25px;
    height: 7%;
    width: 90%;
    background-color: #202225;
    color: white;
    text-align: center;
    outline: none;
    border-color: transparent;
    font-size: 90%;
    vertical-align: middle;
    display: grid;
    grid-template-columns: 9fr 1fr;
    margin-top: 2em;
}

.deleteButton{
    font-family: 'Material Icons';
    font-size: 150%;
    background-color: transparent;
    border: none;
    outline: none;
}

.deleteButton:hover{
    color: #6a6b6d;
    
}


