body{
    background-color: #202225;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */

.invisible{
    display: none;
}

.main{
    height: 100vh;
    width: 100vw;
    display: grid;
}

.doubtsWrapper{
    height: 90vh;
    width: 100vw;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.doubts{
    height: 95%;
    width: 98%;
    background-color: #36393F;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-radius: 25px;
    grid-template-rows: 1fr 10fr;
}

.tabsDiv{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid rgb(179, 179, 179);
}


.toggleContainer{
    border: 2px solid #707070;
    border-radius: 50px;
    height: 35%;
    width: 75%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 50px;
    place-items: center;
    transition: 0.2s all ease;
}

.toggleButtons{
    background-color: transparent;
    color: white;
    font-family: 'Gilroy-Bold';
    font-size: 120%;
    height: 100%;
    width: 100%;
    border: transparent;
    cursor: pointer;
    transition: 0.3s all ease;
}

.toggleAll{
    background-color: transparent;
    color: white;
    background-color: #9667FF;
    font-family: 'Gilroy-Bold';
    font-size: 120%;
    height: 100%;
    border: none;
    width: 100%;
    border-radius: 25px 0px 0px 0px;
    transition: 0.3s all ease;
}

.togglePopular{
    background-color: transparent;
    color: white;
    background-color: #9667FF;
    font-family: 'Gilroy-Bold';
    font-size: 120%;
    height: 100%;
    border: none;
    width: 100%;
    border-radius: 0px 25px 0px 0px;
}

.doubtsContainerDiv{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
}

.containerWrapper{
    margin-top: 2%;
    height: 95%;
    width: 100%;
    overflow: auto;
}

.container{
    display: grid;
    height: fit-content;
    width: fit-content;
    margin-left: 1em;
    margin-bottom: 1.3em;
}

.studentName{
    font-family: 'Uni Sans Bold';
    color: #9667FF;
    font-size: 120%;
    margin:0;
    padding:0;
}

.doubtText{
    font-family: 'Uni Sans Regular';
    font-weight: lighter;
    color: white;
    font-size: 120%;
    margin:0;
    padding:0;
}

.doubtHeader{
    display: flex;
    align-items: center;
}

.popularButton{
    font-family: 'Material Icons';
    font-size: 3vh;
    color: #9667FF;
    background-color: transparent;
    border: none;
    outline: none;
    cursor:pointer;
    transition: 0.2s all ease;
}

.popularButton:hover{
    color: #FFF !important;
}

.videoPreviewWrapper{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.videoPreview{
    background-color: #202225;
    height: 90%;
    width: 90%;
    display: grid;
    place-items: center;
    border-radius: 20px;

}

.videoIframe{
    height: 55%;
    width: 90%;
}