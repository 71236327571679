.loginMain{
    height: 100vh;
    width: 100vw;
    background-color: #121212;
    display: grid;
    place-items: center;
}

.loginBoxWrapper{
    border: 2px solid #676767;
    border-radius: 30px;
    height: 40%;
    width: 25%;
    display:grid;
    grid-template-rows: 2fr 8fr;
}

.invisible{
    display: none;
}

.headerDiv{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.header{
    font-family: 'Gilroy-Bold';
    color: white;
    font-size: 170%;
    margin-bottom: 0%;
}

.loginBox{
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inputWrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.emailInput{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.passwordInput{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.inputBox{
    background: #3E3E3E;
    border: 1px solid #707070;
    border-radius: 20px;
    opacity: 1;
    height: 7vh;
    width: 70%;
    text-align: center;
    font-family: 'Gilroy-Light';
    font-size: 120%;
    color: white;
}

.forgotPass{
    height: 100%;
    width: 75%;
    display: flex;
    justify-content: flex-end;
}

.forgotButton{
    font-family: 'Gilroy-Bold';
    font-weight: bold;
    background-color: transparent;
    color: #9667FF;
    font-size: 100%;
    height: 100%;
    border: transparent;
    cursor: pointer;
    margin-bottom: 2em;
}

.loginButtonWrapper{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loginButton{
    font-family: 'Gilroy-Bold';
    color: white;
    font-size: 170%;
    height: 50%;
    width: 70%;
    background: #6736D3;
    border: 1px solid #707070;
    border-radius: 20px;
    opacity: 1;
}

.resetBoxWrapper{
    border: 2px solid #676767;
    border-radius: 30px;
    height: 50%;
    width: 30%;
}

.resetPass{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 2fr 1fr 1fr;
    place-items: center;
}

.resetPassEmail{
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
}

.resetPassEmailWrapper{
    display: grid;
    place-items: center;
    height: 100%;
    grid-template-rows: 1fr 1fr;

}

.backButtonDivWrapper{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.backButtonDiv{
    height: 50%;
    width: 93%;
}

.backButton{
    font-family: 'Material Icons';
    background-color: transparent;
    color: #707070;
    font-size: 250%;
    border: transparent;
}

.resetInputDiv{
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
}

.labelDiv{
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
}

.resetPassHeader{
    font-family: 'Gilroy-Light';
    font-weight: bold;
    color: #9667FF;
    font-size: 150%;
}

.resetPassEmailBox{
    margin-top: -10%;
    background: #3E3E3E;
    border: 1px solid #707070;
    border-radius: 20px;
    opacity: 1;
    height: 60%;
    width: 90%;
    text-align: center;
    font-family: 'Gilroy-Light';
    font-size: 150%;
    color: white;
}

.resetButtonWrapper{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.resetButton{
    font-family: 'Gilroy-Light';
    font-weight:510;
    color: white;
    font-size: 170%;
    height: 50%;
    width: 50%;
    background: #6736D3;
    border: 1px solid #707070;
    border-radius: 20px;
    opacity: 1;
}

.instructionsDiv{
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
}

.instructions{
    font-family: 'Gilroy-Light';
    font-size: 120%;
    color: white;
}

.spinner {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #9667FF;
    border-right-color: white;
    animation: s2 1s infinite linear;
    z-index: 1;
    position: absolute;
}

.purpleButtonLogin {
    background-color: #6736D3;
    border: none;
    outline: none;
    font-family: 'Gilroy-Bold';
    color: white;
    border-radius: 0.8em;
    height: 6vh;
    width: 70%;
    font-size: 1em;
    transition: 0.3s all ease;
}

.purpleButtonLogin:hover {
    color: #6736D3;
    background-color: white;
    cursor: pointer;
}

.purpleButtonLogin:focus {
    outline: none;
}

.purpleButtonLogin:disabled {
    background-color: #C9C4FF;
    color: white;
    cursor: not-allowed;
}


@keyframes s2 {to{transform: rotate(1turn)}}

.spinnerWrapper {
    height: 100vh;
    width: 100vw;
    background-color: rgb(0, 0, 0, 0.6);
    position: fixed;
    display: grid;
    place-items: center;
    z-index: 100;
}
  
.invisibleWrapper {
    display: none;
}

@media only screen and (min-width:320px) and (max-width:500px) {

.loginBoxWrapper{
    border: 2px solid #676767;
    border-radius: 30px;
    height: 60%;
    width: 90%;
}

.resetBoxWrapper{
    border: 2px solid #676767;
    border-radius: 30px;
    height: 50%;
    width: 90%;
}


.loginBox{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows:2fr 1fr;
}

.invisible{
    display: none;
}

.toggleContainer{
    border: 2px solid #707070;
    border-radius: 50px;
    height: 35%;
    width: 85%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 50px;
    place-items: center;
}

.toggleButtons{
    background-color: transparent;
    color: white;
    font-family: 'Gilroy-Bold';
    font-size: 120%;
    height: 100%;
    width: 100%;
    border: transparent;
}

.toggleEditor{
    background-color: transparent;
    color: #9667FF;
    font-family: 'Gilroy-Bold';
    font-size: 120%;
    height: 100%;
    border: 2px solid #707070;
    width: 100%;
    border-radius: 50px;
}

.toggleStudio{
    background-color: transparent;
    color: #9667FF;
    font-family: 'Gilroy-Bold';
    font-size: 120%;
    height: 100%;
    border: 2px solid #707070;
    width: 100%;
    border-radius: 50px;
}

.studioLogin{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.editorLogin{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.inputBox{
    background: #3E3E3E;
    border: 1px solid #707070;
    border-radius: 20px;
    opacity: 1;
    height: 60%;
    width: 85%;
    text-align: center;
    font-family: 'Gilroy-Light';
    font-size: 150%;
}

.forgotPass{
    height: 100%;
    width: 90%;
    display: flex;
    justify-content: flex-end;
}

.forgotButton{
    font-family: 'Gilroy-Light';
    font-weight: bold;
    background-color: transparent;
    border: transparent;
    color: #9667FF;
    font-size: 120%;
    height: 80%;
    margin-left: auto;
}

.loginButtonWrapper{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;;
}

.resetPassHeader{
    font-family: 'Gilroy-Light';
    font-weight: bold;
    color: #9667FF;
    font-size: 105%;
}

.instructions{
    font-family: 'Gilroy-Light';
    font-size: 95%;
    color: white;
}

}
